import Helpers from './components/helpers'
import Appregistry from './libs/appregistry/appregistry'
import mainHomeView from './views/home/home_view'
import headerMenuView from './views/header/headerMenuView'
import personView from './views/person/personView'
import billsCompositeView from './views/user/bill/billsCompositeView'
import adminSettingsView from './views/admin/settings/adminSettingsView'
import oldBillsView from './views/oldbills/oldBillsView'
import monthlyReportView from './views/admin/reports/monthlyReportView'
import personReportView from './views/admin/reports/personReportView'
import salaryView from './views/salary/salary'
import templatesCompositeView from './views/user/templates/templatesCompositeView'
import staticView from './views/static/staticView'
import calculationView from './views/calculation/calculationView'
import adminSalaryLayout from './views/admin/salaries/adminSalaryLayout'
import adminBillsCompositeView from './views/admin/marionette/bills/adminBillsCompositeView'
import sharedCustomersCompositeView from './views/admin/marionette/sharedcustomers/sharedCustomersLayout'
import customersLayout from './views/user/customers/customersLayout'
import peopleLayout from './views/admin/marionette/people/peopleLayout'
import remunerationLayout from './views/admin/remuneration/remunerationLayout'
import tesLayout from './views/admin/tes/tesLayout'
import tesV2Layout from './views/react/tes/tes-view'
import registerView from './views/register/registerView'
import checkEmail from './views/register/checkEmail'
import confirmRegistration from './views/register/confirmRegistration'
import forgotPassword from './views/password/forgotPassword'
import checkPasswordEmail from './views/password/checkEmail'
import changePassword from './views/password/changePassword'
import passwordChanged from './views/password/passwordChanged'
import ropoView from './views/admin/ropo/ropoView'
import workListView from './views/admin/worklist/workListView'
import contractorView from './views/admin/contractor/contractorView'
import generalReport from './views/admin/reports/general-report'
import bankLayout from './views/admin/marionette/bank/bankLayout'
import quickSightAdvance from './views/admin/reports/quicksight-advance'
import quickSightBills from './views/admin/reports/quicksight-bills'
import quickSightMaksamattomat from './views/admin/reports/quicksight-maksamattomat'
import quickSightPalkkaraportti from './views/admin/reports/quicksight-salaries'

// react
import reactBills from './views/react/bills/bills-view'
import Backbone from 'backbone'

window.this_location = ''

var permitted = [
  'about',
  'rekisteriseloste',
  'logout',
  'register',
  'email',
  'forgot/password',
  'forgot/checkemail',
  'forgot/checkemail',
  'registration/confirm',
  'password/changed',
  'calculation',
  '*actions',
]

var permittedRoutes = function (route) {
  return (
    route === 'about' ||
    route === 'rekisteriseloste' ||
    route === 'logout' ||
    route === 'register' ||
    route === 'email' ||
    route === 'forgot/password' ||
    route === 'forgot/checkemail' ||
    route === 'forgot/password/:id' ||
    route === 'registration/confirm/:id' ||
    route === 'password/changed' ||
    route === 'calculation' ||
    route === '*actions'
  )
}

var views = {}
var pageRegion = new Backbone.Marionette.Region({ el: '#page' })
var headerRegion = new Backbone.Marionette.Region({ el: '#navbar' })

var router = Backbone.Marionette.AppRouter.extend({
  routes: {
    // person views
    bills: 'showBills',
    person: 'showperson',
    logout: 'logout',
    customers: 'showCustomers',
    oldbills: 'showOldBills',
    'oldbills/:billId': 'showOldBills',
    templates: 'showTemplates',
    'admin/people': 'showPeople',
    'admin/worklist': 'showWorkList',
    'admin/contractor': 'showContractorView',
    'admin/bills': 'showAllBills',
    'admin/bills-v2': 'showAllBillsV2',
    'admin/settings': 'showSettings',
    'admin/monthly': 'showMonthlyReport',
    'admin/report/salary-report': 'showPalkkaRaportti',
    'admin/report/person': 'showPersonReport',
    'admin/report/advance': 'showQuickSightAdvance',
    'admin/report/send-bills': 'showQuickSightBills',
    'admin/report/not-paid': 'showQuickSightMaksamattomat',
    'admin/salary': 'showSalaryList',
    'admin/customers': 'showSharedCustomers',
    'admin/customers/:id': 'showSharedCustomers',
    'admin/remunerations': 'showRemunerations',
    'admin/ropo': 'showRopo',
    'admin/tes': 'showTes',
    'admin/tesV2': 'showTesV2',
    'admin/general-report': 'showGeneralReport',
    salaries: 'showSalaries',
    rekisteriseloste: 'showRegister',
    calculation: 'showCalculation',
    about: 'about',
    register: 'register',
    email: 'email',
    'registration/confirm/:id': 'confirmRegister',
    'forgot/password': 'forgotPassword',
    'forgot/password/:id': 'changePassword',
    'forgot/checkemail': 'checkemail',
    'password/changed': 'passwordChanged',
    'admin/bank': 'showBank',
    login: 'defaultAction',
    '*actions': 'defaultAction',
  },
  before: function (route, params) {
    if (!Appregistry.models.person.get('loggedIn')) {
      const current = route ? route : ''
      if (permittedRoutes(current)) {
      } else {
        window.location = '#'
      }
      $('.clear-when-logged').removeClass('hidden')
    } else {
      $('.clear-when-logged').addClass('hidden')
    }
  },
  after: function () {
    var header = headerMenuView

    headerRegion.show(header)
    //headerMenuView.render()
  },
  logout: function () {
    var deferred = Appregistry.logout()
    if (deferred) {
      deferred.always(function () {
        $('.clear-when-logged').removeClass('hidden')
        _.each(views, function (view) {
          if (view.close) {
            view.close()
          }
          view = undefined
        })
        pageRegion.empty()
        views = {}
        Backbone.history.navigate('login', { trigger: true })
        Appregistry.newPerson()
      })
    } else {
      Backbone.history.navigate('login', { trigger: true })
    }
  },
  showGeneralReport: function () {
    document.title = 'Yleisraportti'
    pageRegion.show(new generalReport())
  },
  showWorkList: function () {
    document.title = 'Työ'
    pageRegion.show(new workListView())
  },
  showContractorView: function () {
    document.title = 'Ostolaskut'
    pageRegion.show(new contractorView())
  },
  showCalculation: function () {
    document.title = 'Palkkalaskuri'
    pageRegion.show(new calculationView())
  },
  about: function () {
    views['who'] = new staticView()
    views['who'].whichView = 'who'
    pageRegion.show(views['who'])
  },
  showRegister: function () {
    views['register'] = new staticView()
    views['register'].whichView = 'register'
    pageRegion.show(views['register'])
  },
  showperson: function () {
    pageRegion.show(new personView())
  },
  showBills: function () {
    pageRegion.show(new billsCompositeView())
  },
  showOldBills: function (billId) {
    pageRegion.show(new oldBillsView({ billId: billId }))
  },
  showCustomers: function () {
    pageRegion.show(new customersLayout())
  },
  defaultAction: function () {
    const homeView = new mainHomeView()
    pageRegion.show(homeView)
  },
  showPeople: function () {
    document.title = 'Käyttäjät'
    pageRegion.show(new peopleLayout())
  },
  showAllBills: function () {
    document.title = 'Laskut'
    pageRegion.show(new adminBillsCompositeView())
  },
  showAllBillsV2: function () {
    document.title = 'Laskut'
    pageRegion.show(new reactBills())
  },
  showSettings: function () {
    document.title = 'Asetukset'
    pageRegion.show(new adminSettingsView())
  },
  showSalaries: function () {
    document.title = 'Palkat'
    pageRegion.show(new salaryView())
  },
  showMonthlyReport: function () {
    document.title = 'Kuukausiraportti'
    pageRegion.show(new monthlyReportView())
  },
  showPersonReport: function () {
    document.title = 'Henkilöraportti'
    pageRegion.show(new personReportView())
  },
  showPalkkaRaportti: function () {
    document.title = 'Palkkaraportti'
    pageRegion.show(
      new quickSightPalkkaraportti({
        model: new Backbone.Model.extend({
          alku: '2023-01-01',
          loppu: '2023-01-31',
        }),
      })
    )
  },
  showTemplates: function () {
    pageRegion.show(new templatesCompositeView())
  },
  showSalaryList: function () {
    document.title = 'Palkat'
    pageRegion.show(new adminSalaryLayout())
  },
  showSharedCustomers: function (id) {
    document.title = 'Asiakkaat'
    console.log('model', id)
    pageRegion.show(new sharedCustomersCompositeView({ id: id }))
  },
  showRemunerations: function () {
    pageRegion.show(new remunerationLayout())
  },
  showTes: function () {
    document.title = 'Tes'
    pageRegion.show(new tesLayout())
  },
  showTesV2: function () {
    document.title = 'Tes'
    pageRegion.show(new tesV2Layout())
  },
  register: function () {
    pageRegion.show(new registerView())
  },
  email: function () {
    pageRegion.show(new checkEmail())
  },
  confirmRegister: function (token) {
    pageRegion.show(new confirmRegistration({ token: token }))
  },
  forgotPassword: function () {
    pageRegion.show(new forgotPassword())
  },
  checkemail: function () {
    pageRegion.show(new checkPasswordEmail())
  },
  changePassword: function (token) {
    pageRegion.show(new changePassword({ token: token }))
  },
  passwordChanged: function () {
    pageRegion.show(new passwordChanged())
  },
  showRopo: function () {
    document.title = 'Ropo24'
    pageRegion.show(new ropoView())
  },
  showBank: function () {
    document.title = 'Pankki'
    pageRegion.show(new bankLayout())
  },
  showQuickSightAdvance: function () {
    document.title = 'Ennakot'
    pageRegion.show(new quickSightAdvance())
  },
  showQuickSightBills: function () {
    document.title = 'Laskut'
    pageRegion.show(new quickSightBills())
  },
  showQuickSightMaksamattomat: function () {
    document.title = 'Maksamattomat laskut'
    pageRegion.show(new quickSightMaksamattomat())
  },
})

var initialize = function () {
  var deferred = Appregistry.models.person.checkLogin()
  deferred.always(function () {
    $.when.apply($, Appregistry.authModel.defers).then(function () {
      new router()
      Backbone.history.start()
    })
  })
}
export default {
  initialize: initialize,
  AppRouter: router,
}
