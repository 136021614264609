import Appregistry from 'libs/appregistry/appregistry'
import settingsDtoModel from 'models/admin/settingsDtoModel'
import settingsTemplate from './settings.html'
import employerCostView from 'views/admin/settings/employerCostView'
import converter from 'libs/converter'

var employerCostViewPointers = {}

var populateDbModel = Backbone.Model.extend({
  url: function() {
    return '/api/admin/sharedcustomer/createToElastic'
  },
})

var adminSettingsView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(settingsTemplate)({
      items: serialized,
      people: Appregistry.peopleCollection,
    })
  },
  initialize: function() {
    this.model = new settingsDtoModel({}, { parse: true })
    this.model.fetch()

    this._modelBinder = new Backbone.ModelBinder()
  },
  onDestroy: function() {
    this.model.get('publicSettings').off('change')
  },
  events: {
    'click .populateDB': 'populateDB',
  },
  populateDB: function() {
    let model = new populateDbModel()
    model.save().then(a => $.jGrowl('Asiakasta luotu', { life: 10000 }))
  },
  onRender: function() {
    this.setContect()
    this.addAll()
    var self = this
    _.each(employerCostViewPointers, function(view) {
      self.$el.find('.employercosts').append(view.render().el)
    })
    this.$el.find('.glyphicon-question-sign').popover(Appregistry.popoverOpts)
    return this
  },
  setContect: function() {
    var publicSettings = {
      taxpercent: { selector: '[name=taxpercent]', converter: converter.percentConverter },
      kmreimbursement: { selector: '[name=kmreimbursement]', converter: converter.euroConverter },
      fulldaycompensation_price: { selector: '[name=fulldaycompensation_price]', converter: converter.euroConverter },
      partialdaycompensation_price: {
        selector: '[name=partialdaycompensation_price]',
        converter: converter.euroConverter,
      },
      lunch_compensation: { selector: '[name=lunch_compensation]', converter: converter.euroConverter },
      minimumhourlywage: { selector: '[name=minimumhourlywage]', converter: converter.euroConverter },
      holidaybonus: { selector: '[name=holidaybonus]', converter: converter.percentConverter },
      osk: { selector: '[name=osk]', converter: converter.percentConverter },
      tyEL: { selector: '[name=tyEL]', converter: converter.percentConverter },
      personTyEL: { selector: '[name=personTyEL]', converter: converter.percentConverter },
      sotu: { selector: '[name=sotu]', converter: converter.percentConverter },
      unemploymentInsurance: { selector: '[name=unemploymentInsurance]', converter: converter.percentConverter },
      personUnemploymentInsurance: {
        selector: '[name=personUnemploymentInsurance]',
        converter: converter.percentConverter,
      },
      accidentInsurance: { selector: '[name=accidentInsurance]', converter: converter.percentConverter },
      inverseAlvText: { selector: '[name=inverseAlvText]' },
      inverseAlvText_en: { selector: '[name=inverseAlvText_en]' },
      billFooterText: { selector: '[name=billFooterText]' },
      billFooterText_en: { selector: '[name=billFooterText_en]' },
      sendEmail: { selector: '[name=sendEmail]' },
      mailWhenBillOpen: { selector: '[name=mailWhenBillOpen]' },
      mailWhenBillApproved: { selector: '[name=mailWhenBillApproved]' },
      mailWhenBillClosed: { selector: '[name=mailWhenBillClosed]' },
      mailWhenBillPaid: { selector: '[name=mailWhenBillPaid]' },
      mailWhenSalaryPaid: { selector: '[name=mailWhenSalaryPaid]' },
      applicationUrl: { selector: '[name=applicationUrl]' },
      salaryMax: { selector: '[name=salaryMax]', converter: converter.euroConverter },
      extraTaxPercent: { selector: '[name=extraTaxPercent]', converter: converter.percentConverter },
      osk_cost_1: { selector: '[name=osk_cost_1]', converter: converter.percentConverter },
      osk_cost_2: { selector: '[name=osk_cost_2]', converter: converter.percentConverter },
      osk_cost_3: { selector: '[name=osk_cost_3]', converter: converter.percentConverter },
      osk_cost_4: { selector: '[name=osk_cost_4]', converter: converter.percentConverter },
      alvFromNetto: { selector: '[name=alvFromNetto]', converter: converter.percentConverter },
      percentFromExtIem: { selector: '[name=percentFromExtIem]', converter: converter.percentConverter },
      mailSendSubcontractorApprovalRequest: { selector: '[name=mailSendSubcontractorApprovalRequest]' },
      kmreimbursement_included_to_billing: {
        selector: '[name=kmreimbursement_included_to_billing]',
        converter: converter.euroConverter,
      },
      fulldaycompensation_included_to_billinge: {
        selector: '[name=fulldaycompensation_included_to_billinge]',
        converter: converter.euroConverter,
      },
      partialdaycompensation_included_to_billing: {
        selector: '[name=partialdaycompensation_included_to_billing]',
        converter: converter.euroConverter,
      },
      lunch_compensation_included_to_billing: {
        selector: '[name=lunch_compensation_included_to_billing]',
        converter: converter.euroConverter,
      },
      percentWithoutTaxCard: { selector: '[name=percentWithoutTaxCard]', converter: converter.percentConverter },
      sendBank: { selector: '[name=sendBank]' },
      distraintIban: { selector: '[name=distraintIban]' },
      distraintBic: { selector: '[name=distraintBic]' },
      distraintCreditor: { selector: '[name=distraintCreditor]' },
      advancePercentage: { selector: '[name=advancePercentage]', converter: converter.percentConverter },
      groupLifeInsurance: { selector: '[name=groupLifeInsurance]', converter: converter.percentConverter },
    }
    this._modelBinder.unbind()
    this._modelBinder.bind(this.model.get('publicSettings'), this.el, publicSettings)

    this.model.get('publicSettings').on('change', this.saveModel, this)
  },
  addAll: function() {
    var self = this
    this.model.get('publicSettings').get('employercosts').each(function(cost) {
      self.addOne(cost)
    })
  },
  addOne: function(model) {
    if (employerCostViewPointers[model.id] === undefined) {
      employerCostViewPointers[model.id] = new employerCostView({ model: model })
    }
  },
  saveModel: function(model, event) {
    var changes = Object.keys(this.model.get('publicSettings').changed)
    var self = this
    if (changes.length
      && event.changeSource == 'ModelBinder') {
      this.model.save(null, {
        silent: true,
        success: function() {
          Appregistry.timestampChange(self.$el)
        },
      })
    }

  },
})
export default adminSettingsView

