import React from 'react'
import { NinjouDatePicker } from '../components/date-picker-react'
import { NinjouTimePicker } from '../components/ninjou-time-picker'
import { useFieldArray } from 'react-hook-form'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { DateTime } from 'luxon'

export default ({ nestIndex, control, register, errors, remuneration, setValue, removeRemuneration }) => {

  const index = nestIndex


  const { fields, append, remove } = useFieldArray({
    control,
    name: `remunerations.${nestIndex}.notValid`,
  })

  const validOn = useFieldArray({
    control,
    name: `remunerations.${nestIndex}.validOnDays`,
  })


  React.useEffect((index) => {
    console.log('here', index)
    // remove(0);
  }, [remove])
  const addNewNotValid = (index, e) => {
    e.preventDefault()
    append({ value: DateTime.now().toFormat('dd.MM.yyyy') })
  }

  const addNewValid = () => {
    validOn.append({ value: DateTime.now().toFormat('dd.MM.yyyy') })
  }

  const deleteItem = (notValid) => {
    remove(notValid)
  }

  return (

    <tr key={remuneration.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        <input  {...register(`remunerations.${index}.allowanceName`)} />
      </td>
      <td>
        {validOn.fields.map((valid, validIndex) =>
          <div className="custom" key={valid.id}>
            <NinjouDatePicker className="width-110" control={control}
                              name={`remunerations.${nestIndex}.validOnDays.${validIndex}.value`} />
            <button
              type="button"
              onClick={() => validOn.remove(validIndex)}
              style={{ verticalAlign: 'middle', height: '40px', marginTop: 0, paddingBottom: '5px' }}
            >
              <XCircleIcon
                className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                aria-hidden="true"
              />
            </button>
          </div>,
        )}
        <div>
          <a className="text-blue-800 no-underline hover:underline" onClick={(e) => addNewValid(index, e)}>Lisää</a>
        </div>
      </td>
      <td>
        {fields.map((notValid, notValidIndex) =>
          <div className="custom" key={notValid.id}>
            <NinjouDatePicker className="width-110" control={control}
                              name={`remunerations.${nestIndex}.notValid.${notValidIndex}.value`} />
            <button
              type="button"
              onClick={() => deleteItem(notValidIndex)}
              style={{ verticalAlign: 'middle', height: '40px', marginTop: 0, paddingBottom: '5px' }}
            >
              <XCircleIcon
                className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                aria-hidden="true"
              />
            </button>
          </div>,
        )}
        <div>
          <a className="text-blue-800 no-underline hover:underline" onClick={(e) => addNewNotValid(index, e)}>Lisää</a>
        </div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        <div className="relative flex items-start">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={`remunerations.${index}.mondays`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                {...register(`remunerations.${index}.mondays`)}
              />
            </div>
            <div className="ml-3 pt-0.5 text-sm leading-6">
              <label htmlFor={`remunerations.${index}.mondays`} className="font-medium text-gray-900">
                Maanantai
              </label>
            </div>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={`remunerations.${index}.tuesdays`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                {...register(`remunerations.${index}.tuesdays`)}
              />
            </div>
            <div className="ml-3 pt-0.5 text-sm leading-6">
              <label htmlFor={`remunerations.${index}.tuesdays`} className="font-medium text-gray-900">
                Tiistai
              </label>
            </div>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={`remunerations.${index}.wednesdays`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                {...register(`remunerations.${index}.wednesdays`)}
              />
            </div>
            <div className="ml-3 pt-0.5 text-sm leading-6">
              <label htmlFor={`remunerations.${index}.wednesdays`} className="font-medium text-gray-900">
                Keskiviikko
              </label>
            </div>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={`remunerations.${index}.thursdays`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                {...register(`remunerations.${index}.thursdays`)}
              />
            </div>
            <div className="ml-3 pt-0.5 text-sm leading-6">
              <label htmlFor={`remunerations.${index}.thursdays`} className="font-medium text-gray-900">
                Torstai
              </label>
            </div>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={`remunerations.${index}.fridays`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                {...register(`remunerations.${index}.fridays`)}
              />
            </div>
            <div className="ml-3 pt-0.5 text-sm leading-6">
              <label htmlFor={`remunerations.${index}.fridays`} className="font-medium text-gray-900">
                Perjantai
              </label>
            </div>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={`remunerations.${index}.saturdays`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                {...register(`remunerations.${index}.saturdays`)}
              />
            </div>
            <div className="ml-3 pt-0.5 text-sm leading-6">
              <label htmlFor={`remunerations.${index}.saturdays`} className="font-medium text-gray-900">
                Lauantai
              </label>
            </div>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={`remunerations.${index}.sundays`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                {...register(`remunerations.${index}.sundays`)}
              />
            </div>
            <div className="ml-3 pt-0.5 text-sm leading-6">
              <label htmlFor={`remunerations.${index}.sundays`} className="font-medium text-gray-900">
                Sunnuntai
              </label>
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        <div>
          <NinjouTimePicker className="width-110" control={control} name={`remunerations.${index}.validFrom`} />
        </div>
        <div>
          <NinjouTimePicker className="width-110" control={control} name={`remunerations.${index}.validTo`} />
        </div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        <div>
          <NinjouDatePicker className="width-110" control={control} name={`remunerations.${index}.validFromDate`} />
        </div>
        <div>
          <NinjouDatePicker className="width-110" control={control} name={`remunerations.${index}.validToDate`} />
        </div>
      </td>

      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        <input
          type="number"
          step="0.01"
          className="width-110"
          {...register(`remunerations.${index}.allowancePrice`)}
        />
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              type="checkbox"
              id={`remunerations.${index}.useOnlyForBaseSalary`}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              {...register(`remunerations.${index}.useBaseSalary`)}
            />
          </div>
          <div className="ml-3 pt-0.5 text-sm leading-6">
            <label htmlFor={`remunerations.${index}.useBaseSalary`} className="font-medium text-gray-900">
              Tuntipalkka
            </label>
          </div>
        </div>
      </td>
      <td className="relative whitespace-nowrap py-4 pr-4 text-sm font-medium sm:pr-0">
        <input
          type="number"
          step="0.01"
          className="width-110"
          {...register(`remunerations.${index}.remunerationPercent`)}
        />
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              type="checkbox"
              id={`remunerations.${index}.useOnlyForBaseSalary`}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              {...register(`remunerations.${index}.useOnlyForBaseSalary`)}
            />
          </div>
          <div className="ml-3 pt-0.5 text-sm leading-6">
            <label htmlFor={`remunerations.${index}.useOnlyForBaseSalary`} className="font-medium text-gray-900">
              Vain tuntipalkkaan
            </label>
          </div>
        </div>

      </td>
      <td className="relative whitespace-nowrap py-4 pr-4 text-sm font-medium sm:pr-0">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => removeRemuneration(index)}
        >
          Poista
        </button>
      </td>
    </tr>
  )
};
