import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { numToFixed } from './bills'
import axios from 'axios'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import { PersonInfo } from './person-info-react'
import CustomerCard from './customer-card-react'
import BillCard from './bill-card-react'
import { Ostolaskut } from './ostolaskut-react'
import { Alihankinnat } from './alihankinnat-react'
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()
export const SideView = ({ selectedBill, setSelectedBill, token }) => {
  const [billId, setBillId] = useState(undefined)
  const [receiptUrl, setReceiptUrl] = useState('')
  const billableItems = selectedBill.billableitems || []
  const show = !!selectedBill?.id
  const getWaypoint = (points) => {
    const arr = points || []
    return arr.join(' - ')
  }
  const openReceipt = (url) => {
    setReceiptUrl(url)
  }
  const getReceipts = (receipts) => {
    const arr = receipts || []
    return arr.map((receipt) => {
      const url = `/api/billableitem/receipt/${receipt.id}?authtoken=${token}`
      return (
        <div className="text-gray-900">
          <a onClick={() => openReceipt(url)}>{receipt.destinationName}</a>
        </div>
      )
    })
  }

  if (billId !== selectedBill.id && selectedBill.id) {
    setBillId(selectedBill.id)
    axios
      .get(`/api/admin/bills/${selectedBill.id}`, {
        headers: {
          authtoken: token,
        },
      })
      .then((reply) => {
        setSelectedBill(reply.data)
      })
  }

  const closeSide = () => {
    setReceiptUrl('')
    setBillId(undefined)
    setSelectedBill({})
  }
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const loginUrl = `/api/admin/bills/redirect/${selectedBill.id}?authtoken=${token}`
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeSide}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-full">
                  <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Lasku {selectedBill.id} asiakkaalle {selectedBill.sharedCustomer?.name}
                              <p>Alv {selectedBill.alv * 100}%</p>
                              <p>
                                {loginUrl && (
                                  <a className="text-base font-semibold leading-6 text-blue-500" href={loginUrl}>
                                    Tarkastele laskua käyttäjän näkymässä
                                  </a>
                                )}
                              </p>
                            </Dialog.Title>
                          </div>
                          <div className="flex h-7 items-center">
                            <button type="button" className="relative text-gray-400 hover:text-gray-500" onClick={() => closeSide()}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-3 pt-6 px-4 sm:px-6 lg:px-8">
                        <BillCard bill={selectedBill} />
                        <PersonInfo person={selectedBill.person} />
                        <CustomerCard customer={selectedBill.sharedCustomer} />
                      </div>

                      {/*  person info */}

                      {/* Divider container */}
                      <div className="px-4 sm:px-6 lg:px-8">
                        <div className="mt-8 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <p className="text-xl">Laskutusrivit</p>
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Päiväys
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Kohde
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Hinta
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Kulukorvaukset
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Reitti
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Kuitit ja kulukorvaukset
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Kuitit
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {billableItems.map((item) => (
                                      <tr key={item.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{item.date}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.destination}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <div className="text-gray-900">Alv: {numToFixed(item.price)} €</div>
                                          <div className="text-gray-900">Alv0%: {numToFixed(item.priceWithoutAlv)} €</div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <div className="text-gray-900">Kilometrit: {item.kilometers}</div>
                                          <div className="text-gray-900">Osapäivä: {item.partialdaycompensation}</div>
                                          <div className="text-gray-900">Kokopäivä: {item.fulldaycompensation}</div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <div className="text-gray-900">Ateriat: {item.lunchcompensation}</div>
                                          <div className="text-gray-900">Ulkomaan päiväraha: {item.foreignAllowance}</div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <div className="text-gray-900">{item.addressStart}</div>
                                          <div className="text-gray-900">{getWaypoint(item.wayPoint)}</div>
                                          <div className="text-gray-900"> {item.addressStop}</div>
                                          {item.roundtrip && <div className="text-gray-900">{item.addressStart}</div>}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {numToFixed(item.billedReimbursements)} €
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getReceipts(item.receipts)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Ostolaskut ostolaskut={selectedBill.extContractorBillableItems} token={token} />
                      <Alihankinnat alihankinnat={selectedBill.contractorBillableItems} />
                    </div>
                    {receiptUrl && (
                      <div>
                        <Document onLoadSuccess={onDocumentLoadSuccess} file={receiptUrl}>
                          <Page pageNumber={pageNumber} />
                        </Document>
                        <p>
                          Sivu {pageNumber} / {numPages}
                        </p>
                      </div>
                    )}
                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => closeSide()}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
