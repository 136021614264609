import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { SideView } from './bill-side-over'
import { UsersIcon } from '@heroicons/react/20/solid'
import Pagination from './pagination'

const stateElement = (state) => {
  let options = {}
  switch (state) {
    case 'OPEN': // avoin
      options = {
        state: 'Avoin',
        bgColor: 'bg-cyan-50',
        textColor: 'text-gray-600',
      }
      break
    case 'PENDING': // hyväksyttävänä
      options = {
        state: 'hyväksyttävänä',
        bgColor: 'bg-lime-300',
        textColor: 'text-gray-600',
      }
      break
    case 'TAKEN': // ei käytössä
      options = {
        state: 'Ei käytössä',
        bgColor: 'bg-orange-300',
        textColor: 'text-gray-900',
      }
      break
    case 'CLOSED': // lähetetty
      options = {
        state: 'Lähetetty',
        bgColor: 'bg-cyan-400',
        textColor: 'text-gray-900',
      }
      break
    case 'APPROVED': // hyväksytty
      options = {
        state: 'hyväksytty',
        bgColor: 'bg-lime-300',
        textColor: 'text-gray-900',
      }
      break
    case 'PAID': // Maksettu
      options = {
        state: 'Maksettu',
        bgColor: 'bg-green-500',
        textColor: 'text-white',
      }
      break
    case 'LOCKED': // lukitty
      options = {
        state: 'Lukittu',
        bgColor: 'bg-teal-300',
        textColor: 'text-gray-900',
      }
      break
    case 'HYVITETTY':
      options = {
        state: 'Hyvitetty',
        bgColor: 'bg-amber-300',
        textColor: 'text-gray-900',
      }
      break
    case 'OPEN_REQUESTED':
      options = {
        state: 'Avauspyyntö',
        bgColor: 'bg-pink-300',
        textColor: 'text-gray-900',
      }
      break
    case 'LUOTTOTAPPIO':
      options = {
        state: 'Luottotappio',
        bgColor: 'bg-red-500',
        textColor: 'text-gray-900',
      }
      break
  }
  const classNames = `inline-flex items-center rounded-md ${options.bgColor} px-2 py-1 text-xs font-medium ${options.textColor} ring-1 ring-inset ring-green-600/20`
  return <span className={classNames}>{options.state}</span>
}

export const numToFixed = (num) => {
  const safe = isNaN(num) ? 0 : num
  return safe.toFixed(2)
}

const buttonsForBill = (bill, send, open, hyvita) => {
  switch (bill.state) {
    case 'PENDING': // hyväksyttävänä
      return (
        <button onClick={() => send(bill)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
          Lähetä
        </button>
      )
      break
    case 'TAKEN': // ei käytössä
      return (
        <button onClick={() => open(bill)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
          Avaa
        </button>
      )
      break
    case 'CLOSED': // lähetetty
      return (
        <button
          onClick={() => hyvita(bill)}
          className="bg-amber-300 hover:bg-amber-500 text-white font-bold py-2 px-4 border border-amber-500 rounded"
        >
          Hyvitä
        </button>
      )
      break
    case 'APPROVED': // hyväksytty
      return (
        <button onClick={() => open(bill)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
          Avaa
        </button>
      )
      break
    case 'PAID': // Maksettu
      return (
        <button
          onClick={() => open(bill)}
          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 border border-orange-700 rounded"
        >
          Avaa
        </button>
      )
      break
    case 'LOCKED': // lukitty
      return (
        <button onClick={() => open(bill)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
          Avaa
        </button>
      )
      break
    case 'HYVITETTY':
      return (
        <button onClick={() => open(bill)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
          Avaa
        </button>
      )
      break
    case 'OPEN_REQUESTED':
      return (
        <button onClick={() => open(bill)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
          Avaa
        </button>
      )
      break
    case 'LUOTTOTAPPIO':
      break
  }
}

export const Bills = ({ token }) => {
  const [billReply, setBillReply] = useState([])
  const [selectedBill, setSelectedBill] = useState({})
  const [page, setPage] = useState(0)
  const [state, setState] = useState({
    search: '',
    status: '',
  })
  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setState((state) => ({
      ...state,
      [e.target.name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(state)
    axios
      .get(`/api/admin/bills/v2?search=${state.search}&stateCrit=${state.status}`, {
        headers: {
          authtoken: token,
        },
      })
      .then((reply) => setBillReply(reply.data))
  }

  useEffect(() => {
    axios
      .get(`/api/admin/bills/v2?page=${page}`, {
        headers: {
          authtoken: token,
        },
      })
      .then((reply) => {
        setBillReply(reply.data)
      })
  }, [page])
  const send = (bill) => {
    axios
      .post(`/api/admin/bills/${bill.id}/sendtobilling`, bill, {
        headers: {
          authtoken: token,
        },
      })
      .then((reply) => {
        console.log('reply')
        setBillReply({
          ...billReply,
          bills: billReply.bills.map((b) => {
            if (b.id === bill.id) {
              return reply.data
            }
            return b
          }),
        })
      })
  }
  const open = () => {
    axios
      .post(`/api/admin/bills/v2/${bill.id}/open`, bill, {
        headers: {
          authtoken: token,
        },
      })
      .then((reply) => {
        console.log('reply')
        setBillReply({
          ...billReply,
          bills: billReply.bills.map((b) => {
            if (b.id === bill.id) {
              return reply.data
            }
            return b
          }),
        })
      })
  }
  const hyvita = () => {
    axios
      .post(`/api/admin/bills/${bill.id}/sendtohyvitys`, bill, {
        headers: {
          authtoken: token,
        },
      })
      .then((reply) => {
        console.log('reply')
        setBillReply({
          ...billReply,
          bills: billReply.bills.map((b) => {
            if (b.id === bill.id) {
              return reply.data
            }
            return b
          }),
        })
      })
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Hae laskuttajaa, laskutettavaa, laskun id:tä
          </label>
          <div className="mt-2 flex rounded-md shadow-sm space-x-4">
            <div>
              <select
                id="status"
                name="status"
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue=""
                value={state.status}
                onChange={handleChange}
              >
                <option value="">Kaikki</option>
                <option value="OPEN">Avoin</option>
                <option value="PENDING">hyväksyttävänä</option>
                <option value="CLOSED">Lähetetty</option>
                <option value="APPROVED">hyväksytty</option>
                <option value="PAID">Maksettu</option>
                <option value="LOCKED">Lukittu</option>
                <option value="HYVITETTY">Avauspyyntö</option>
                <option value="LUOTTOTAPPIO">Luottotappio</option>
              </select>
            </div>
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="search"
                id="email"
                className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Ninjou Jani "
                value={state.search}
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Hae
            </button>
          </div>
        </form>
      </div>

      <SideView selectedBill={selectedBill} setSelectedBill={setSelectedBill} token={token} />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Id / numero
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Asiakas / Laskuttaja
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Eräpäivä / Maksettu
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Summa
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Alv
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {billReply.bills?.map((bill) => {
                  return (
                    <tr key={bill.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div className="flex">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{bill.id}</div>
                            <div className="font-medium text-gray-900">{bill.billNumber}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div className="flex">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{bill.sharedCustomer.name}</div>
                            <div className="mt-1 text-gray-500">{bill.person.fullName}</div>
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{bill.exactDueDate}</div>
                        <div className="mt-1 text-gray-500">{bill.payDate}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {stateElement(bill.state)}
                        </div>
                        {bill.billSummary?.hasReimbursements && (
                          <div className="mt-1 text-gray-500">
                            <span className="inline-flex items-center rounded-md bg-yellow-300 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-yellow-600/20">
                              Sisältää kulukorvauksia
                            </span>
                          </div>
                        )}
                        {bill.openRequest && (
                          <div className="mt-1 text-gray-500">
                            <span className="inline-flex items-center rounded-md bg-yellow-300 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-yellow-600/20">
                              Avauspyyntö
                            </span>
                          </div>
                        )}
                        {bill.billSummary?.receipts > 0 && (
                          <div className="mt-1 text-gray-500">
                            <span className="inline-flex items-center rounded-md bg-pink-300 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-pink-600/20">
                              Sisältää kuitteja
                            </span>
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{numToFixed(bill.totalpriceWithAlv)} €</div>
                        <div className="mt-1 text-gray-500">{numToFixed(bill.openamount)} €</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{numToFixed(bill.alv)} %</div>
                        <div className="mt-1 text-gray-500">{bill.inverseALV ? 'Käänteinen ALV' : ''}</div>
                        <div className="mt-1 text-gray-500">{bill.constructionReimbursements ? 'Rakennusala alv 0%' : ''}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{buttonsForBill(bill, send, open, hyvita)}</td>
                      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <a onClick={() => setSelectedBill(bill)} className="text-indigo-600 hover:text-indigo-900">
                          Tarkasta<span className="sr-only">, {bill.name}</span>
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination billReply={billReply} page={page} setPage={setPage} />
    </div>
  )
}
